<template>
  <v-card>
    <v-card-title class="py-3 px-4 border-bottom">
      <span class="gray--text text--darken-3 font-weight-regular">
        {{ isEditMode ? "Məhsulun redaktəsi" : "Yeni məhsul" }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="createItemForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Adı"
              filled
              dense
              v-model="formData.name"
              :rules="formRules.name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Modeli"
              filled
              dense
              v-model="formData.model"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="number"
              label="Mövcud sayı"
              filled
              dense
              required
              min="0"
              v-model="formData.availableCount"
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="mb-3">
            <v-autocomplete
              label="Vahidi"
              filled
              dense
              clearable
              hide-details
              item-text="value"
              item-value="id"
              v-model="formData.unitId"
              :items="units"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="3" class="mb-3">
            <v-autocomplete
              label="Rəngi"
              filled
              dense
              clearable
              hide-details
              item-text="value"
              item-value="id"
              v-model="formData.colorId"
              :items="colors"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="3" class="mb-3">
            <v-autocomplete
              label="Ölçüsü"
              filled
              dense
              clearable
              hide-details
              item-text="value"
              item-value="id"
              v-model="formData.sizeId"
              :items="sizes"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="6">
            <v-textarea
              type="text"
              label="Təsviri"
              filled
              dense
              required
              auto-grow
              rows="1"
              v-model="formData.description"
            ></v-textarea>
          </v-col>
          <v-col cols="6">
            <v-col cols="12" class="py-0">
              <span class="ml-2">Yenidir?</span>
            </v-col>
            <v-col cols="12">
              <v-radio-group
                class="py-0 my-0"
                v-model="formData.isNew"
                row
                hide-details
              >
                <v-radio class="ml-2" label="Hə" :value="true"></v-radio>
                <v-radio class="ml-2" label="Yox" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-col>

          <v-col cols="6">
          <v-col cols="12" class="py-0">
            <span class="ml-2">İşləkdir?</span>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              class="py-0 my-0"
              v-model="formData.isWorking"
              row
              hide-details
            >
              <v-radio class="ml-2" label="Hə" :value="true"></v-radio>
              <v-radio class="ml-2" label="Yox" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
           </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="createItemForm"
        color="success"
        depressed
      >
        {{ isEditMode ? "Redaktə et" : "Yarat" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    toggleDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: false,
      default: () => null,
    },
    units: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
    sizes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formValidation: false,
      formData: {
        name: null,
        model: null,
        isNew: true,
        isWorking: true,
        availableCount: 0,
        unitId: null,
        colorId: null,
        sizeId: null,
        description: null,
      },
      formRules: {
        name: [(v) => !!v || "Məhsul adını daxil edin"],
      },
    };
  },
  computed: {
    isEditMode() {
      return (
        this.selectedItem && this.selectedItem.id && this.selectedItem.id > 0
      );
    },
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = { ...newVal };
        } else {
          this.resetFormData();
        }
      },
    },
  },
  methods: {
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("formSubmit", this.formData);
      }
    },
    resetFormData() {
      this.formData = {
        name: null,
        model: null,
        isNew: true,
        isWorking: true,
        availableCount: 0,
        unitId: null,
        colorId: null,
        sizeId: null,
        description: null,
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
  },
};
</script>
